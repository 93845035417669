import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../components/seo';
import BlogPostItem from '../components/blog-post-item';

import Breadcrumbs from '../components/breadcrumbs';

const BlogPostsLayoutContainer = styled.div`
	display: flex;
	padding: 2em 1em;
	flex-wrap: wrap;
`;

const BlogPage = props => (
	<StaticQuery
		query={graphql`
			query GetAllBlogPosts {
				allContentfulBlogPost(
					sort: { fields: publishDate, order: DESC }
				) {
					edges {
						node {
							id
							title
							slug
							metaDescription
							body {
								body
							}
							author {
								name
								avatar {
									gatsbyImageData(width: 150, quality: 100)
								}
							}
							category {
								name
								slug
							}
							publishDate(formatString: "MMMM Do, YYYY")
							tags
							heroImage {
								gatsbyImageData(
									width: 400
									quality: 70
									placeholder: NONE
								)
							}
						}
					}
				}
			}
		`}
		render={data => {
			return (
				<>
					<Layout pageProps={props}>
						<div className="bg-white border-b border-gray-100">
							<nav
								className="flex max-w-7xl mx-auto  px-4 sm:px-6 lg:px-8 py-3"
								aria-label="Breadcrumb"
							>
								<Breadcrumbs
									links={[
										{ to: '/', label: 'Home' },
										{
											to: props.location.pathname,
											label: `Blog`
										}
									]}
								/>
							</nav>
						</div>
						<SEO
							title={
								'Blog - Exterior Renovation Blog Ideas, Tips & News | Bay Area Siding Company'
							}
							description={
								'Bay Area Siding Company Offers Project Ideas, Trends, Tips and More!'
							}
						/>
						<div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
							<div className="py-2">
								{/* <h1 className='font-display font-bold text-2xl md:text-5xl'>Blog</h1> */}
								{/* <p>
                  Project ideas, trends, and tips for residential builders,
                  remodelers, and architects serving the residential construction
                  industry.
                </p> */}
							</div>

							<div className="-mx-4 flex flex-wrap">
								{data.allContentfulBlogPost.edges.map(
									(item, i) => (
										<BlogPostItem
											index={i}
											key={item.node.id}
											body={item.node.body.body}
											avatar={item.node.author.avatar}
											title={item.node.title}
											slug={item.node.slug}
											image={item.node.heroImage}
											publishDate={item.node.publishDate}
											category={item.node.category.name}
											categorySlug={
												item.node.category.slug
											}
											author={item.node.author.name}
											excerpt={item.node.metaDescription}
										/>
									)
								)}
							</div>
						</div>
					</Layout>
				</>
			);
		}}
	/>
);

export default BlogPage;
